@import "../../../constants/colors";

.wrap {
  position: relative;
  display: block;
  padding-top: 700px;
  padding-bottom: 454px;
  pointer-events: none;
  margin-top: -758px;
  z-index: 1;

  @media only screen and (max-width: 900px) {
    margin-top: 0;
    padding: 25px 0;
  }
}

.waveTop {
  position: absolute;
  top: 0;
  left: calc(50% - 10px);
  transform: translateX(-50%);
  pointer-events: none;
  width: 4096px;
  height: 717px;
}

.waveBottom {
  position: absolute;
  bottom: 0;
  left: calc(50% - 10px);
  transform: translateX(-50%);
  width: 4096px;
  height: 454px;
  pointer-events: none;
}

.logoBobbleWrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

.blobTop {
  position: absolute;
  top: 265px;
  left: -330px;

  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .06));
  animation: blobTopLevitation 10s ease-in-out infinite;
}

@keyframes blobTopLevitation {
  50% {
    filter: drop-shadow(0 8px 8px rgba(0, 0, 0, .04));
    transform: translateY(-30px);
  }
}

.logoBobble {
  position: absolute;
  top: -172px;
  right: -290px;
  width: 960px;
  height: 920px;
  pointer-events: none;
}

.bobblesWrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.bobbles {
  position: absolute;
  top: 4px;
  right: -8px;
  display: block;
  width: 515px;
  height: 467px;
  margin-left: -80px;
}

.bobble {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: -1px 2px 9px -2px rgba(0,0,0,0.10);
  background: #fff;
  will-change: transform;
}

.bobbleYandex {
  top: 0;
  left: 211px;
  width: 86px;
  height: 86px;
}

.bobbleWM {
  top: 44px;
  left: 410px;
  width: 68px;
  height: 68px;
}

.bobbleVisa {
  top: 177px;
  left: 83px;
  width: 100px;
  height: 100px;
}

.bobbleMir {
  top: 287px;
  right: 0;
  width: 100px;
  height: 100px;
}

.bobbleMactercard {
  top: 323px;
  left: 0;
  width: 84px;
  height: 84px;
}

.bobbleQiwi {
  bottom: 0;
  left: 158px;
  width: 120px;
  height: 120px;
}

.contentWrap {
  position: relative;
  display: block;
  pointer-events: auto;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 160px;
    bottom: 0;
    background-color: #fff;
  }
}

.content {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin: -165px -68px -165px;

  @media only screen and (max-width: 1080px) {
    margin-left: -28px;
    margin-right: -28px;
  }

  @media only screen and (max-width: 900px) {
    display: block;
    margin: 0;
  }
}

.block {
  flex: 1;
  position: relative;
  display: block;
  margin: 0 28px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba($tomeBlack, 0.01), 0 2px 12px 0 rgba(0,0,0, 0.06);

  &:last-child {
    margin-top: 48px;
  }

  @media only screen and (max-width: 900px) {
    margin: 0;
  }
}

.blockHeader {
  position: relative;
  display: flex;
  align-items: center;
  padding: 33px 140px 31px 40px;
  border-bottom: 1px solid rgba($tomeBlack, 0.06);

  @media only screen and (max-width: 900px) {
    padding: 12px 30px 32px 30px;
  }
}

.blockHeaderContent {
  position: relative;
  display: block;
  flex: 1;
}

.blockTitle {
  position: relative;
  display: block;
  margin: 0 0 22px;
  letter-spacing: 0.31px;
  font-weight: 600;
  font-size: 27px;
  line-height: 32px;
  color: $fontBlack;

  @media only screen and (max-width: 900px) {
    margin-bottom: 1px;
    letter-spacing: -0.6px;
    font-size: 24px;
    line-height: 56px;
  }
}

.blockText {
  position: relative;
  display: block;
  margin: 0;
  letter-spacing: -0.24px;
  font-size: 17px;
  line-height: 28px;
  color: $fontBlack;

  @media only screen and (max-width: 900px) {
    z-index: 1;
    padding-right: 50px;
  }
}

.blockCircleWrap {
  position: absolute;
  right: 48px;
  bottom: -48px;

  &:before {
    content: '';
    position: absolute;
    left: -18px;
    right: -18px;
    top: -18px;
    bottom: -18px;
    background-color: #fff;
  }

  @media only screen and (max-width: 900px) {
    right: -12px;

    &:before {
      right: 50%;
    }
  }
}

.blockCircleLineRefill,
.blockCircleLinePayments {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media only screen and (max-width: 900px) {
    display: none;
  }
}

.blockCircleLineRefill {
  top: -80px;
}

.blockCircleLinePayments {
  top: -84px;
}

.blockCircle {
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 96px;
  height: 96px;
  border-radius: 50%;
}

.blockCircleRefill {
  background: linear-gradient(135deg, rgba(76,233,245,1) 0%, rgba(183,216,105,1) 100%);
  box-shadow: 2px 5px 7px 0 rgba(58,144,0,0.12);
}

.blockCirclePayments {
  background: #F8CA00;
  box-shadow: 2px 5px 7px 0 rgba(187,152,0,0.12);
}

.blockCircleContent {
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.3px;
  font-size: 20px;
  color: $fontBlack;
  line-height: 56px;
  text-align: center;
  border-radius: 50%;
  background-color: #fff;
}

.blockCircleText {
  margin: 0 5px;
  font-weight: 500;
  font-size: 30px;
}

.blockFooter {
  position: relative;
  display: block;
  padding: 24px 40px 26px;

  @media only screen and (max-width: 900px) {
    padding: 28px 70px 23px 30px;
  }
}

.blockList {
  position: relative;
  display: block;
  margin: 5px 0 0;
  padding: 0;
  letter-spacing: -0.24px;
  font-size: 17px;
  line-height: 26px;
  color: $fontBlack;
}

.blockListItem {
  position: relative;
  display: block;
  padding-left: 24px;
  margin-bottom: 12px;
}

.blockListItemPin {
  display: block;
  position: absolute;
  left: 0;
  top: 6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-image: linear-gradient(135deg, #fff 0%, #EDEDEE 100%);
  }
}

.blockListItemPinGreen {
  background-image: linear-gradient(135deg, #76BC11 0%, #5FBB11 100%);
}
.blockListItemPinOrange {
  background-image: linear-gradient(135deg, #FF9B04 0%, #FC910C 100%);
}
