@import "../../constants/colors";

.wrap {
  position: relative;
  display: block;
  background-color: #fff;

  @media only screen and (max-width: 900px) {
    background: transparent;
    top: -41px;
    z-index: 1;
  }
}

.content {
  position: relative;
  display: flex;
  width: 100%;
  padding: 63px 0 90px;

  @media only screen and (min-width: 901px) {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -40px;
      right: -40px;
      border-top: 2px solid rgba(0,0,0,0.06);
    }
  }

  @media only screen and (max-width: 900px) {
    padding: 0 0 45px;
  }
}

.contentLeft {
  position: relative;
  display: block;
  width: 37%;
  min-width: 115px;

  @media only screen and (max-width: 900px) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
  }
}

.socialLinkWrap {
  position: relative;
  display: block;

  @media only screen and (min-width: 901px) {
    max-width: 110px;
    margin-bottom: 30px;
  }
}

.socialLink {
  position: relative;
  display: flex;
  margin: 0 0 16px;
  font-weight: 500;
  font-size: 17px;
  line-height: 19px;
  color: $fontBlack;

  &:last-child { margin-bottom: 0; }
  &:hover { color: #e02130 }
}

.socialLinkImageWrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  margin-right: 14px;
}

.socialLinkVk {
  position: relative;
  top: 1px;
  //width: 18px;
  height: 13px;
}

.copyright {
  position: relative;
  display: block;
  letter-spacing: 0;
  font-size: 17px;
  color: #858585;
  line-height: 24px;

  @media only screen and (max-width: 900px) {
    margin-right: 5px;
  }
}

.copyrightImage {
  position: relative;
  display: inline-block;
  left: -2px;
  width: 28px;
  height: 27px;
  margin-bottom: 4px;
  margin-right: 4px;
}

.contentRight {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.linksColumn {
  flex: 1;
  position: relative;
  display: block;
  max-width: 165px;

  &:nth-child(2) {
    left: -4px;
  }
  &:last-child {
    left: -8px;
    max-width: 100px;
  }
}

.linksTitle {
  position: relative;
  display: block;
  margin: 0 0 25px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #858585;
}

.linkWrap {
  position: relative;
  display: flex;
}

.link {
  position: relative;
  display: block;
  margin: 0 0 16px;
  font-size: 17px;
  line-height: 19px;
  color: $fontBlack;
  transition: all 0.1s;

  &:hover { color: #e02130 }
}

.linkTooltip {
  position: relative;
  display: inline-block;
  top: 1px;
  width: 21px;
  height: 20px;
  margin-left: 11px;
  border-radius: 3px;
  text-align: center;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 0.55px;
  line-height: 20px;
  color: rgba($fontBlack, 0.50);
  background: rgba(#000, 0.05);
  cursor: pointer;
}
