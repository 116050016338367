@import "../constants/colors.scss";

@-webkit-keyframes autofill {
  to {
    background: #fff;
    color: $fontBlack;
  }
}

form textarea.ant-input {
  margin-bottom: 0;
  resize: none;
  min-height: 76px;
}

.ant-form-item-control {
  line-height: 34px;
}

.ant-input,
.ant-select-selector,
.ant-input-number-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  padding: 4px 10px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  border-color: rgba($tomeBlack, 0.16);
  border-radius: 4px;
  color: $fontBlack;

  @media only screen and (max-width: 1000px) {
    font-size: 15px;
  }
}

.ant-input[disabled],
.ant-input-number-input[disabled],
.ant-select-selector[disabled],
.ant-input[disabled]::-webkit-input-placeholder,
.ant-input-number-input[disabled]::-webkit-input-placeholder,
.ant-select-selector[disabled]::-webkit-input-placeholder {
  color: rgba($fontBlack, 0.25);
}

.ant-input:-webkit-autofill,
.ant-input:-webkit-autofill:hover,
.ant-input:-webkit-autofill:focus,
.ant-input:-webkit-autofill:active,
.ant-input-number-input:-webkit-autofill,
.ant-input-number-input:-webkit-autofill:hover,
.ant-input-number-input:-webkit-autofill:focus,
.ant-input-number-input:-webkit-autofill:active {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.ant-input::-webkit-input-placeholder,
.ant-select-selector::-webkit-input-placeholder,
.ant-input-number::-webkit-input-placeholder {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: rgba($tomeBlack, 0.5);
}

.ant-input,
.ant-input-number .ant-input-number-input {
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
}
.ant-input-number { height: 40px }

.ant-input-sm,
.ant-input-number-sm .ant-input-number-input {
  height: 34px;
  padding-left: 10px;
  padding-right: 10px;

  @media only screen and (max-width: 1000px) {
    height: 48px;
    font-size: 15px;
  }
}
.ant-input-number-sm {
  height: 34px;
  @media only screen and (max-width: 1000px) {
    height: 48px;
  }
}

.ant-input-lg,
.ant-input-number-lg .ant-input-number-input {
  height: 44px;
  font-size: 19px;
  padding-left: 10px;
  padding-right: 10px;
}
.ant-input-number-lg { height: 44px }

.short-input .ant-form-item-children-icon { // использовать на FormItem
  display: none !important;
}

.ant-input:hover,
.ant-input-number:hover,
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: $inputYellow;
}

.ant-input-affix-wrapper {
  padding: 0;
  border: none !important;
  box-shadow: none !important;

  &:before {
    display: none;
  }

  .ant-input:not(:last-child) {
    padding-right: 25px;
  }
  .ant-input-lg:not(:last-child) {
    padding-right: 35px;
  }
  .ant-input-suffix {
    left: auto !important;
  }
  > input.ant-input {
    padding: 4px 10px;
    border: 1px solid rgba(18, 20, 19, 0.16);

    &:hover {
      border-color: $inputYellow;
    }
    &:focus {
      border-color: rgba(239, 202, 0, 0.78);
      box-shadow: inset 0 1px 1px 1px #f9f9f8, 0 0 0 3px rgba(249, 207, 0, 0.43);
    }
  }
}

.ant-input-affix-wrapper-auto {
  &.ant-input {
    background-color: #fbfbfb;
    &:hover {
      border-color: rgba(193, 193, 193, 0.78) !important;
    }
    &:focus {
      border-color: rgba(193, 193, 193, 0.78) !important;
      box-shadow: inset 0 1px 1px 1px #f9f9f8, 0 0 0 3px rgba(197, 197, 197, 0.43) !important;
    }
  }
}

.ant-input:focus,
.ant-input-number-focused,
.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: rgba(239, 202, 0, 0.78);
  box-shadow: inset 0 1px 1px 1px #f9f9f8, 0 0 0 3px rgba(249, 207, 0, 0.43);
}

.ant-form-item {
  font-family: 'Roboto', sans-serif;
  margin-bottom: 0;
}

.ant-form-item-children {
  display: block;
}

.ant-form-item-children-icon {
  display: none;
}

.has-success.has-feedback .ant-form-item-children:after {
  content: '';
  position: absolute;
  top: 20px;
  right: 16px;
  height: 7px;
  width: 7px;
  border-radius: 3.5px;
  background: $pointGreen;
  margin: -3.5px 0 0;
}

.ant-form-item-has-error {
  * {
    color: $warningRed;
  }

  &.ant-input-affix-wrapper {
    > input.ant-input {
      padding-right: 11px;
      border-color: $warningRed;
      color: $warningRed;
      &:focus {
        box-shadow: inset 0 1px 1px 0.5px #f8f8f7, 0 0 0 3px rgba($warningRed, 0.2) !important;
      }
    }
  }

  .ant-input:not(.ant-input-disabled) {
    padding-right: 11px;
    border-color: $warningRed !important;
    color: $warningRed !important;

    &:focus {
      box-shadow: inset 0 1px 1px 0.5px #f8f8f7, 0 0 0 3px rgba($warningRed, 0.2) !important;
    }
  }

  .ant-input::-webkit-input-placeholder,
  .ant-select-selection-placeholder {
    color: $warningRed !important;
  }

  &.has-feedback {
    .ant-form-item-children:after {
      content: '';
    }
  }
}

.ant-input-group-addon {
  width: 40px;
  background: #fff;
}

.operation_refund_input {
  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 45px;
  }
}

.card-csc-input {
  .has-error.has-feedback {
    .ant-input:not(.ant-input-disabled) {
      padding-right: 20px;
    }
    .ant-form-item-children-icon {
      width: 20px;

      &:before {
        margin-right: 9px;
      }
    }
  }
}

.phone-prefix {
  .ant-input-prefix {
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    align-items: center;
    width: 40px;
    height: 22px;
    margin-top: -11px;
    text-align: center;
    line-height: 22px;
    z-index: 1;
  }
}
