@import "../../../constants/colors";

.wrap {
  position: relative;
  display: block;
  padding-top: 700px;
  padding-bottom: 454px;
  pointer-events: none;
  margin-top: -758px;
  z-index: 1;

  @media only screen and (max-width: 900px) {
    margin-top: 0;
    padding: 25px 0;
  }
}

.waveTop {
  position: absolute;
  top: 0;
  left: calc(50% - 10px);
  transform: translateX(-50%);
  pointer-events: none;
  width: 4096px;
  height: 717px;
}

.waveBottom {
  position: absolute;
  bottom: 0;
  left: calc(50% - 10px);
  transform: translateX(-50%);
  width: 4096px;
  height: 454px;
  pointer-events: none;
}

.logoBobbleWrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

.blobTop {
  position: absolute;
  top: 265px;
  left: -330px;

  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .06));
  animation: blobTopLevitation 10s ease-in-out infinite;
}

@keyframes blobTopLevitation {
  50% {
    filter: drop-shadow(0 8px 8px rgba(0, 0, 0, .04));
    transform: translateY(-28px);
  }
}

.blobBottom {
  position: absolute;
  right: -140px;
  bottom: -195px;

  filter: drop-shadow(0 8px 8px rgba(0, 0, 0, .02));
  animation: blobTopLevitation 10s ease-in-out infinite;
}

@keyframes blobTopLevitation {
  50% {
    filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .04));
    transform: translateY(30px);
  }
}

.illustrationPin {
  position: absolute;
  top: 6px;
  right: 20px;
  width: 340px;
  height: 360px;
}

.bobblesWrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.bobbles {
  position: absolute;
  top: 4px;
  right: 2px;
  display: block;
  width: 517px;
  height: 451px;
  margin-left: -80px;
}

.bobble {
  position: absolute;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: -1px 2px 9px -2px rgba(0,0,0,0.10);
  background: #fff;
  will-change: transform;
}

.bobbleFirst {
  top: 0 !important;
  left: 221px !important;
  width: 86px;
  height: 86px;
}

.bobbleSecond {
  top: 52px !important;
  left: 424px !important;
  width: 68px;
  height: 68px;
}

.bobbleThird {
  top: 148px !important;
  left: 84px !important;
  width: 100px;
  height: 100px;
}

.bobbleFourth {
  top: 251px !important;
  left: 417px !important;
  width: 100px;
  height: 100px;
}

.bobbleFifth {
  top: 309px !important;
  left: 0 !important;
  width: 84px;
  height: 84px;
}

.bobbleSixth {
  top: 330px !important;
  left: 199px !important;
  width: 120px;
  height: 120px;
}

.content {
  position: relative;
  display: block;
  pointer-events: auto;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 140px;
    bottom: 100px;
    background-color: #fff;
  }
}

.row {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 60px;

  &:first-child {
    flex-direction: row-reverse;
    margin-top: -140px;
  }
  &:last-child {
    margin-bottom: -169px;
  }

  @media only screen and (max-width: 900px) {
    display: block;
    margin: 0;

    &:first-child {
      margin: 0;
    }
    &:last-child {
      margin: 0;
    }
  }
}

.card {
  position: relative;
  display: block;
  height: 400px;
  border-radius: 8px;
  pointer-events: none;

  @media only screen and (max-width: 900px) {
    height: auto;
  }
}

.cardMap {
  overflow: hidden;
  width: 452px;
  height: 380px;
  pointer-events: auto;

  @media only screen and (max-width: 900px) {
    width: auto;
    height: 290px;
    margin-top: -33px;
    margin-left: -14px;
    margin-right: -14px;
  }
}

.cardMapTop {
  @media only screen and (min-width: 901px) {
    margin-right: 18px;
  }
}

.cardMapBottom {
  @media only screen and (min-width: 901px) {
    margin-left: 18px;
  }

  .cardMapPin {
    transform: rotateY(180deg) translate(50%, -100%);
  }
}

.cardMapPin {
  position: relative;
  display: block;
  transform: translate(-75%, -100%);
}

.cardInfo {
  flex: 1;
  width: 491px;
  min-height: 400px;
  z-index: 1;

  @media only screen and (max-width: 900px) {
    width: 100%;
    height: auto;
    box-shadow: 0 0 0 1px rgba($tomeBlack, 0.01), 0 2px 12px 0 rgba(0,0,0,0.06);
    border-radius: 8px;
    background-color: #fff;
  }
}

.cardInfoTop {
  padding-left: 15px;
  margin-left: 18px;
  margin-right: -40px;

  .cardInfoBg {
    left: -15px;
  }
  .cardContent {
    left: 15px;
  }
  .cardTextFlag {
    right: 78px;
  }

  @media only screen and (max-width: 900px) {
    padding: 0;
    margin: 0;

    .cardContent {
      left: 0;
    }
    .cardTextFlag {
      top: -8px;
      right: 20px;
      width: 44px;
    }
  }
}

.cardInfoBottom {
  padding-right: 15px;
  margin-right: 18px;
  margin-left: -40px;

  .cardInfoBg {
    right: -15px;
    transform: rotateY(180deg);
  }
  .cardContent {
    right: 15px;
  }
  .cardTextFlag {
    right: 97px;
  }

  @media only screen and (max-width: 900px) {
    padding: 0;
    margin: 48px 0 0;

    .cardContent {
      right: 0;
    }
    .cardTextFlag {
      top: -8px;
      right: 20px;
      width: 44px;
    }
  }
}

.cardInfoBg {
  position: absolute;
  top: -11px;
}

.cardTextFlag {
  position: absolute;
  top: -9px;
  right: 55px;
  width: 52px;
}

.cardContent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: auto;
  padding: 19px 40px;

  @media only screen and (max-width: 900px) {
    position: relative;
    display: block;
    padding: 12px 30px 24px;
  }
}

.cardTitle {
  position: relative;
  display: block;
  margin: 0 0 10px;
  letter-spacing: -0.65px;
  font-weight: 600;
  font-size: 27px;
  line-height: 56px;
  color: $fontBlack;

  @media only screen and (max-width: 900px) {
    margin: 0;
    letter-spacing: -0.6px;
    font-size: 24px;
    line-height: 56px;
  }
}

.cardText {
  position: relative;
  display: block;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0;
  font-size: 17px;
  line-height: 28px;
  color: $fontBlack;
}

.cardTextStrong {
  font-weight: 500;
}

.cardHr {
  position: relative;
  display: block;
  margin: 32px 0 29px;
  border: none;
  border-top: 1px solid rgba(0,0,0, 0.06);
  background: none;
}
