@import "../../../constants/colors";

.wrap {
  position: relative;
  display: block;
  padding-bottom: 55px;

  @media only screen and (max-width: 900px) {
    padding-top: 72px;
    padding-bottom: 197px;
  }
}

.waveMobileBottom {
  position: absolute;
  bottom: -1px;
  left: calc(50% - 10px);
  transform: translateX(-50%);
  width: 940px;
  height: 155px;
}

.bubbleMobile {
  position: absolute;
  bottom: -190px;
  right: 8px;
  width: 141px;
}

.content {
  position: relative;
  display: block;

  @media only screen and (min-width: 901px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.textWrap {
  position: relative;
  display: block;
}

.title {
  position: relative;
  display: block;
  margin: 0 0 14px;
  letter-spacing: -0.65px;
  font-weight: 600;
  font-size: 27px;
  line-height: 48px;
  color: $fontBlack;

  @media only screen and (max-width: 900px) {
    letter-spacing: -0.6px;
    font-size: 24px;
    line-height: 32px;
  }
}

.text {
  position: relative;
  display: block;
  margin: 0;
  font-size: 17px;
  line-height: 28px;
  color: $fontBlack;
}

.buttonsWrap {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 43px;

  @media only screen and (min-width: 901px) {
    justify-content: flex-end;
    padding-top: 18px;
  }
}

.callButton,
.writeButton {
  width: auto;
  padding: 0 18px;
}

.writeButton {
  margin-left: 24px;
}
