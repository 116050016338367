@import "../../../constants/colors";

.wrap {
  position: relative;
  display: block;
  padding: 355px 0 446px;
  margin-top: -380px;
  margin-bottom: -257px;

  background: linear-gradient(rgba(#000, 0.012), rgba(#000, 0.018));

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(rgba(#000, 0.02), rgba(#000, 0.03));
    pointer-events: none;
  }

  @media only screen and (max-width: 900px) {
    padding: 0;
    margin: 0;
    background: none;

    &:before {
      display: none;
    }
  }
}

.shadowWave {
  position: absolute;
  top: -77px;
  left: calc(50% + 70px);
  transform: translateX(-50%);
}

.illustration {
  position: absolute;
  top: -398px;
  right: -206px;
  width: 902px;
  height: 902px;
}

.bubble {
  position: absolute;
  top: 120px;
  right: -123px;

  transform: translateY(-10px);
  filter: drop-shadow(0 8px 8px rgba(0, 0, 0, .02));
  animation: blobTopLevitation 10s ease-in-out infinite;
}

@keyframes blobTopLevitation {
  50% {
    filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .04));
    transform: translateY(30px);
  }
}

.info {
  flex: 1;
  position: relative;
  display: block;
  max-width: 410px;
}

.title {
  position: relative;
  display: block;
  margin: 0 0 19px;
  letter-spacing: -0.65px;
  font-weight: 600;
  font-size: 27px;
  line-height: 38px;
  color: $fontBlack;
}

.text {
  position: relative;
  display: block;
  margin: 0;
  font-size: 17px;
  line-height: 28px;
  color: $fontBlack;
}

.contentWrap {
  position: relative;
  display: block;
  margin-top: 55px;

  @media only screen and (max-width: 900px) {
    margin-top: 32px;
  }
}

.contentRow {
  position: relative;
  display: flex;
  margin-left: -64px;
  margin-right: -64px;

  @media only screen and (max-width: 1080px) {
    margin-left: -24px;
    margin-right: -24px;
  }
  @media only screen and (max-width: 900px) {
    display: block;
    margin: 0;
  }
}

.content {
  flex: 1;
  position: relative;
  display: block;
  margin: 24px;

  @media only screen and (max-width: 900px) {
    margin: 0 0 32px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 144px;
  padding: 0 40px;
  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba($tomeBlack, 0.01) ,0 3px 8px 0 rgba(0,0,0, 0.04);
  background-color: #fff;

  @media only screen and (max-width: 900px) {
    height: 136px;
    padding: 0 30px;
  }
}

.boxLogosWarp {
  position: relative;
  display: flex;
  align-items: center;
  height: 28px;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 23px;
}

.boxLogo {
  position: relative;
  display: block;
  margin: 0 4px 0 10px;
}

.boxInfo {
  position: relative;
  display: flex;
}

.boxName {
  position: relative;
  display: block;
  margin: 4px 0 0 0;
  padding-right: 5px;
  letter-spacing: -0.24px;
  font-size: 17px;
  line-height: 28px;
  color: $fontBlack;
  white-space: nowrap;
}

.boxPercentWrap {
  position: relative;
  display: block;
  margin: 0;
  padding-left: 5px;
  letter-spacing: -0.24px;
  font-size: 17px;
  line-height: 28px;
  color: $fontBlack;
}

.boxPercent {
  margin-right: 4px;
  font-weight: 500;
  font-size: 26px;
}
