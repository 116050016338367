@import "../../constants/colors";

$animationTime: 300ms;

.wrap {
  position: relative;
  display: block;
  width: 387px;
  height: 380px;
  perspective: 1000px;
}

.cardBack {
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  width: 372px;
  height: 380px;
  transform: translate3d(-35px, 0, -120px);
  opacity: 1;
  z-index: 1;
  will-change: transition, transform, opacity;
  pointer-events: none;

  .cardBackground {
    background: #fff;
    box-shadow: 0 0 0 1px rgba($tomeBlack, 0.01), -2px 2px 8px 0 rgba(0,0,0, 0.12);
  }
}

.cardBackAnimation {
  transition: all $animationTime;
  opacity: 0;
  transform: translate3d(-70px, 0, -240px);

  .cardBackground {
    transition: all $animationTime;
    background: linear-gradient(-180deg, rgba(255,255,255,0.02) 10%, rgba(0,0,0,0.02) 96%);
    box-shadow: -2px 5px 8px 0 rgba(0,0,0, 0.10);
  }
}

.card {
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  width: 372px;
  height: 380px;
  transform: translate3d(40px, 0, 120px);
  opacity: 0;
  z-index: 4;
  will-change: transform, z-index, opacity;
  pointer-events: none;
}

.cardAnimation {
  transition: all $animationTime;

  .cardBackground {
    transition: all $animationTime;
  }
}

.cardShow,
.cardShowing {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  z-index: 3;
  pointer-events: auto;

  .cardBackground {
    background: #fff;
    box-shadow: 0 0 0 1px rgba($tomeBlack, 0.01), -2px 2px 8px 0 rgba(0,0,0, 0.12);
  }
}

.cardExiting {
  transform: translate3d(-35px, 0, -120px);
  z-index: 2;

  .cardBackground {
    background: #fff;
    box-shadow: 0 0 0 1px rgba($tomeBlack, 0.01), -2px 2px 8px 0 rgba(0,0,0, 0.12);
  }
}

.cardBackground {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: skewX(3deg) skewY(-2deg);
  border-radius: 8px;

  will-change: background, box-shadow;
}

.cardContent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
