@import "../../constants/colors";

.wrap {
  position: relative;
  display: block;
  margin-top: -110px;
  padding-top: 110px;
  padding-bottom: 504px;
  z-index: 1;
  background: linear-gradient(to bottom, rgba(245,245,245,1) 0%, rgba(250,250,250,1) 100%);

  @media only screen and (max-width: 900px) {
    padding-top: 104px;
    padding-bottom: 130px;
    background: none;
  }
}

.content {
  position: relative;
  display: block;
  width: 570px;
  padding-top: 48px;
  margin-bottom: -42px;

  @media only screen and (max-width: 900px) {
    width: 100%;
    padding-top: 26px;
  }
}

.title {
  position: relative;
  display: block;
  margin: 0 0 32px;
  text-align: left;
  letter-spacing: -1.2px;
  font-weight: 600;
  font-size: 58px;
  line-height: 62px;
  color: $fontBlack;

  @media only screen and (max-width: 900px) {
    margin-bottom: 36px;
    letter-spacing: -0.8px;
    font-size: 32px;
    line-height: 44px;
  }
}

.text {
  position: relative;
  display: block;
  max-width: 438px;
  margin: 0;
  letter-spacing: 0;
  font-size: 17px;
  line-height: 30px;
  color: $fontBlack;
}

.textFootnote {
  position: relative;
  display: inline-block;
  padding: 0 5px;
  border: 1px solid rgba(0,0,0, 0.12);
  border-radius: 4px;
  line-height: 26px;
  background: rgba(0,0,0, 0.02);
  color: $fontBlack;
  cursor: pointer;

  &:active {
    background: rgba(0,0,0, 0.04);
  }
}

.button {
  width: 174px;
  height: 44px;
  font-weight: 500;
}
