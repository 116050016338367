.close {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    .closeCrossGray {
      opacity: 0;
    }
    .closeCrossRed {
      opacity: 1;
    }
  }
}

.closeCross {
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 0.2s;
  transform: translate(-50%, -50%);
}

.closeCrossGray {
  opacity: 0.38;
}
.closeCrossRed {
  opacity: 0;
}

