@import "../../../constants/colors";

.wrap {
  position: relative;
  display: block;
  padding: 355px 0 446px;
  margin-bottom: -224px;
  margin-top: -402px;

  @media only screen and (min-width: 901px) {
    background: linear-gradient(rgba(#000, 0.012), rgba(#000, 0.018));

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(rgba(#000, 0.02), rgba(#000, 0.03));
      pointer-events: none;
    }
  }
  @media only screen and (max-width: 900px) {
    padding: 0;
    margin: 0;
  }
}

.backgroundMap {
  position: absolute;
  right: -500px;
  top: -550px;
  width: 1400px;
  height: 973px;
}

.content {
  position: relative;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 900px) {
    display: block;
  }
}

.info {
  flex: 1;
  position: relative;
  display: block;
  margin-right: 80px;
  max-width: 430px;
}

.title {
  position: relative;
  display: block;
  margin: 0 0 19px;
  letter-spacing: -0.65px;
  font-weight: 600;
  font-size: 27px;
  line-height: 38px;
  color: $fontBlack;
}

.text {
  position: relative;
  display: block;
  margin: 0;
  font-size: 17px;
  line-height: 28px;
  color: $fontBlack;
}

.blocksWrap {
  flex: 1;
  position: relative;
  display: block;
  margin-right: -40px;

  @media only screen and (max-width: 900px) {
    flex: none;
    margin: 0;
  }
}

.block {
  position: relative;
  display: flex;
  align-items: center;
  height: 136px;
  margin-bottom: 24px;
  padding: 0 40px;
  box-shadow: 0 0 0 1px rgba($tomeBlack, 0.01),0 3px 8px 0 rgba(0,0,0,0.06);
  border-radius: 8px;
  background-color: #fff;
  //cursor: pointer;

  &:last-child { margin-bottom: 0; }

  &:hover {
    box-shadow: 0 0 0 1px rgba($tomeBlack, 0.01),0 4px 9px 0 rgba(0,0,0,0.06);
    .link { display: none; }
    .linkActive { display: block; }
  }

  @media only screen and (max-width: 900px) {
    padding: 0 30px;
  }
}

.blockText {
  flex: 1;
  position: relative;
  display: block;
  margin: 0 80px 0 39px;
  letter-spacing: 0.19px;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  line-height: 28px;
  color: $fontBlack;

  @media only screen and (max-width: 900px) {
    margin: 0 0 0 25px;
  }
}

.link { display: block; }
.linkActive { display: none; }
