@import "../../constants/colors";

.block {
  position: relative;
  display: block;
}

.mobileBottom {
  position: relative;
  display: block;

  @media only screen and (max-width: 900px) {
    padding-top: 130px;
    background: linear-gradient(rgba(#000, 0.012), rgba(#000, 0.018));

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(rgba(#000, 0.02), rgba(#000, 0.03));
      pointer-events: none;
    }
  }
}

.headerImageMobile,
.waveMobileTop {
  position: absolute;
  left: calc(50% - 10px);
  transform: translateX(-50%);
}

.waveMobileTop {
  top: 0;
  width: 940px;
  height: 155px;
}

.headerImageMobile {
  bottom: 0;
  width: 940px;
  height: 564px;
}

.followWrap {
  position: relative;
  display: inline-block;
  margin-top: 42px;
}

.follow {
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 20px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 0 15px rgba($tomeBlack, 0.05);

  &:hover {
    .followText {
      color: $tomeRed;
    }
  }
}

.followText {
  margin-left: 6px;
  font-weight: 500;
  text-align: left;
  letter-spacing: 0;
  font-size: 17px;
  line-height: 32px;
  color: $fontBlack;
}
