@import "../../../../constants/colors";

.wrap {
  position: relative;
  display: block;
  padding-top: 50px;
  z-index: 1;

  @media only screen and (max-width: 900px) {
    padding-top: 25px;
    padding-bottom: 10px;
  }
}

.content {
  position: relative;
  display: flex;

  @media only screen and (min-width: 1080px) {
    margin-left: -40px;
    margin-right: -36px;
  }
  @media only screen and (max-width: 900px) {
    display: block;
  }
}

.column {
  flex: 1;
  position: relative;
  display: block;

  @media only screen and (min-width: 901px) {
    &:first-child {
      margin-right: 26px;
    }
    &:last-child {
      padding-top: 57px;
      margin-left: 25px;

      .card:nth-child(2) {
        margin-left: -8px;
        margin-right: 8px;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    flex: none;
  }
}

.card {
  position: relative;
  display: block;
  padding: 19px 40px 31px;
  margin-bottom: 48px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba($tomeBlack, 0.01), 0 2px 12px 0 rgba(0,0,0, 0.06);

  @media only screen and (min-width: 901px) {
    &:last-child {
      margin-bottom: 5px;
    }
  }
  @media only screen and (max-width: 900px) {
    padding: 19px 30px 24px;
    margin-bottom: 32px;
  }
}

.cardTitle {
  position: relative;
  display: block;
  margin: 0 0 9px;
  letter-spacing: -0.65px;
  font-weight: 600;
  font-size: 27px;
  line-height: 56px;
  color: $tomeBlack;

  @media only screen and (max-width: 900px) {
    font-size: 24px;
  }
}

.cardText {
  position: relative;
  display: block;
  margin: 0;
  font-size: 17px;
  line-height: 28px;
  color: $tomeBlack;
}

.cardLink {
  display: inline-block;
  padding: 0 5px;
  border-radius: 3px;
  background: hsla(0,0%,72.9%,.18);
  color: #272727;

  &:hover {
    color: $tomeRed;
  }
}

.cardImagesWrap {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 18px;

  i {
    position: relative;
    margin-right: 20px;

    @media only screen and (max-width: 900px) {
      margin-right: 18px;
    }
  }
}

.creditCardWrap {
  position: relative;
  box-sizing: border-box;
  padding-right: 50px;
  padding-bottom: 20px;
  margin-bottom: 40px;

  @media only screen and (min-width: 1081px) {
    display: flex;
    justify-content: flex-end;

    &:hover {
      .creditCard {
        transform: translate3d(-32px, -10px, 0);
      }
    }
  }
  @media only screen and (max-width: 900px) {
    display: block;
    width: 100%;
    max-width: 424px;
    padding-right: 12px;
    padding-bottom: 11px;
    margin: 0 auto 32px;
  }
}

.creditCardBack {
  position: absolute;
  display: block;
  bottom: 11px;
  right: 32px;
  width: 392px;
  height: 248px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI0OCIgdmlld0JveD0iMCAwIDM5MiAyNDgiIHdpZHRoPSIzOTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgeDE9IjUwJSIgeDI9IjUwJSIgeTE9IjAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwIiBzdG9wLW9wYWNpdHk9Ii4wOCIvPjxzdG9wIG9mZnNldD0iMSIgc3RvcC1vcGFjaXR5PSIuMDYiLz48L2xpbmVhckdyYWRpZW50PjxnIGZpbGw9InVybCgjYSkiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZD0ibTM4OC4wMTc2NiA2Ljg1MDY1MDI4LTguMDE3NjYtMS40NDM5MTItNDAtNS40MDY3MzgyOHYxOS4yODc1OTc3bDUyLTguODczNTk5NWMtLjU0ODU2Mi0xLjg0NDE3MDUyLTIuMDg4Nzc0LTMuMjIyMzMyOC0zLjk4MjM0LTMuNTYzMzQ3OTJ6Ii8+PHBhdGggZD0ibTQ3LjAxNzY1OTggMjAzLjg1MDY1LTguMDE3NjU5OC0xLjQ0MzkxMi00MC01LjQwNjczOHY0OS4yODc1OThsNTItMzguODczNmMtLjU0ODU2MTktMS44NDQxNy0yLjA4ODc3MzktMy4yMjIzMzMtMy45ODIzNDAyLTMuNTYzMzQ4eiIgdHJhbnNmb3JtPSJtYXRyaXgoMCAxIDEgMCAtMTk2LjY0Mzc5OSAxOTYuNjQzNzk5KSIvPjwvZz48L3N2Zz4=");
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;

  @media only screen and (max-width: 900px) {
    width: calc(100% - 33px);
    bottom: 0;
    right: 0;
  }
}

.creditCardFront {
  position: absolute;
  bottom: 0;
  right: 24px;
  width: 399px;
  height: 260px;

  @media only screen and (max-width: 900px) {
    width: calc(100% - 25px);
    bottom: -11px;
    right: -8px;
    height: auto;
  }
}

.creditCard {
  position: relative;
  display: block;
  width: 412px;
  will-change: transform;
  transition: transform 250ms ease-in-out;

  @media only screen and (min-width: 1081px) {
    height: 259px;
    transform: translate3d(6px, 0, 0);
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    max-width: 412px;
    bottom: -3px;
  }
}

.tie {
  position: absolute;
  top: -10px;
  right: 84px;

  @media only screen and (max-width: 900px) {
    top: -7px;
    right: 16px;
    width: 30px;
  }
}

.bubbles {
  position: absolute;
  top: 290px;
  right: -225px;
  width: 258px;
  pointer-events: none;
}

.blobBig {
  position: absolute;
  top: 290px;
  right: -225px;
  pointer-events: none;

  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .06));
  animation: bigBlogLevitation 10s ease-in-out infinite;
}

@keyframes bigBlogLevitation {
  50% {
    filter: drop-shadow(0 8px 8px rgba(0, 0, 0, .04));
    transform: translateY(-28px);
  }
}

.blobSmall {
  position: absolute;
  top: 432px;
  right: -83px;
  pointer-events: none;

  filter: drop-shadow(0 8px 8px rgba(0, 0, 0, .06));
  animation: smallBlogLevitation 8s ease-in-out infinite;
}

@keyframes smallBlogLevitation {
  50% {
    filter: drop-shadow(0 2px 2px rgba(0, 0, 0, .04));
    transform: translateY(26px);
  }
}

.dashes {
  position: absolute;
  bottom: 100px;
  left: -250px;
}
