.button {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100px;
  border: none;
  background: none;
  z-index: 2;

  &:focus {
    outline: none;
  }
}

.blob {
  position: absolute;
  top: 0;
  right: 0;
}

.cross {
  position: absolute;
  top: 31px;
  right: 31px;
}
