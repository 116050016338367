@import "../constants/colors.scss";

.ant-notification {
  width: 326px;
  margin-right: 52px;

  @media only screen and (max-width: 1000px) {
    max-width: calc(100vw - 16px);
    width: calc(100vw - 16px);
    margin-right: 8px;
  }
}

.custom-notification {
  &.ant-notification-notice {
    overflow: visible;
    margin-bottom: 38px;
    padding: 25px 64px 25px 90px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: inset 0 0 0 1px rgba(18,20,19,0.01), 0 2px 28px 0 rgba(0,0,0,0.13);

    @media only screen and (max-width: 1000px) {
      padding: 25px 60px 25px 24px;
    }
  }

  .ant-notification-notice-icon {
    top: 14px;
    left: 26px;
    margin: 0;
  }

  .ant-notification-notice-message {
    display: none;
  }

  .ant-notification-notice-description {
    margin: 0;
    letter-spacing: 0;
    font-size: 14px;
    line-height: 22px;
    color: $fontBlack;

    @media only screen and (max-width: 1000px) {
      font-size: 15px;
      line-height: 24px;
    }
  }

  .ant-notification-notice-btn {
    margin: 0;
  }

  .ant-notification-notice-close {
    display: none;
  }

  &.custom-notification-error.ant-notification-notice {
    padding-left: 32px;
    border-left: 4px solid #EA2C3B;
  }
  &.custom-notification-success.ant-notification-notice {
    border-left: 4px solid #009323;
  }
}