@import "../../constants/colors";

$animationTime: 200ms;

.wrap {
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 50;
  pointer-events: none;
  background-color: transparent;
  transition: background-color $animationTime;
  will-change: background-color;
}

.wrapShow {
  overflow: hidden;
  pointer-events: auto;
  background-color: rgba(#000, 0.2);
}

.closeCup {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  background: none;
  border: none;
  &:focus { outline: none }
}

.hamburger {
  box-sizing: content-box;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 28px;
  width: 26px;
  height: 22px;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  pointer-events: auto;

  &:focus {
    outline: none;
  }
}

.contentWrap {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 312px;
  padding-left: 32px;
  background: #F9F9F8;
  box-shadow: inset 0 0 0 1px rgba(255,255,255,0.30), 0 0 3px 0 rgba(18,20,19,0.03), 0 0 6px 0 rgba(0,0,0,0.06), 0 0 4px -3px rgba(18,20,19,0.15);
  transition: transform $animationTime;
  transform: translateX(100%);
  will-change: transform;
  pointer-events: auto;
}

.contentWrapShow {
  transform: translateX(0%);
}

.contentBlob {
  position: absolute;
  top: 0;
  right: 0;
  width: 105px;
}

.cupImage {
  position: absolute;
  right: 0;
  bottom: 29px;
  width: 80px;
  height: 389px;
  pointer-events: none;
}

.contentBackgroundOpen {
  transform: translate(20%, -40%);
}

.content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.linksWrap {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 100%;
  padding: 30px 48px;
  border-bottom: 1px solid rgba($tomeBlack, 0.1);

  &:last-child {
    border-bottom: none;
  }
}

.link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 19px;
  letter-spacing: 0;
  font-weight: 400;
  font-size: 17px;
  line-height: 37px;
  color: $fontBlack;

  &:last-child {
    margin-bottom: 0;
  }
}

.linkBusiness {
  display: inline-block;
  margin-left: -12px;
  margin-right: 12px;
  padding: 0 12px;
  border-radius: 4px;
  box-shadow: inset 0 0 0 2px #7EE3C1;
}

.linkBusinessActive {
  background-color: #C1F7E5;
}

.linksBottom {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.linkSignIn {
  margin: 0;
}

.linkSignInImage {
  margin-top: -1px;
  margin-left: 8px;
}
