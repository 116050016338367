@import "../../../../constants/colors";

.wrap {
  position: relative;
  display: block;
  padding-bottom: 50px;
}

.blobs {
  position: absolute;
  bottom: 100px;
  left: 0;
  z-index: 10;
}

.wave {
  position: absolute;
  bottom: -50px;
  left: calc(50% - 18px);
  width: 4096px;
  height: 528px;
  transform: translateX(-50%);
  z-index: 10;
  pointer-events: none;
}

.waveBlobs {
  position: absolute;
  bottom: 68px;
  left: calc(50% - 800px);
  width: 293px;
  height: 404px;
  transform: translateX(-50%);
  z-index: 10;
  pointer-events: none;
}

.waveBlobBig,
.waveBlobsSmall {
  position: absolute;
  transform: translate(-50%, 0);
  z-index: 10;
  pointer-events: none;
}

.waveBlobBig {
  left: calc(50% - 760px);
  bottom: 273px;
  width: 214px;
  height: 198px;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .04));
  animation: bigBlogLevitation 10s ease-in-out infinite;
}

@keyframes bigBlogLevitation {
  50% {
    filter: drop-shadow(0 8px 8px rgba(0, 0, 0, .02));
    transform: translate(-50%, -30px);
  }
}

.waveBlobsSmall {
  left: calc(50% - 823px);
  bottom: 209px;
  width: 100px;
  height: 96px;
  filter: drop-shadow(0 18px 12px rgba(0, 0, 0, .04));
  animation: smallBlogLevitation 8s ease-in-out infinite;
}

@keyframes smallBlogLevitation {
  50% {
    filter: drop-shadow(0 12px 8px rgba(0, 0, 0, .02));
    transform: translate(-50%, 26px);
  }
}

.content {
  position: relative;
  display: flex;
  width: 100%;
}

.contentLeft {
  position: relative;
  display: block;
  width: 497px;
  box-sizing: border-box;
}

.cardWrap {
  position: relative;
  display: inline-block;
  height: 370px;
  padding-left: 10px;
  perspective: 1000px;
}

.cardFirst,
.cardSecond,
.cardThird,
.cardFourth,
.cardFifth {
  position: relative;
  display: block;
}

.cardFirst {
  margin-left: -5px;
  margin-top: 33px;
}

.cardSecond {
  margin: 20px 0 0 20px;
}

.cardThird {
  margin: 23px 0 0 20px;
}

.cardFourth {
  margin: 34px 0 0 18px;
}

.cardFifth {
  margin: 20px 0 0 15px;
}

.maskArrow {
  position: absolute !important;
  top: 127px;
  right: -12px;
  background-color: #FEFFF5 !important;
  z-index: 6;
  transition: transform 0.1s;
  transform: translate3d(0, 0, 0);
  will-change: transform;

  &:active {
    transform: translate3d(4px, 0, -20px);
  }
}

.contentRight {
  flex: 1;
  position: relative;
  display: block;
  padding-top: 78px;
  min-height: 220px;
  z-index: 1;
}

.tabButtonsWrap {
  position: relative;
  display: flex;
  margin: 0 -7px;
}

.tab {
  position: relative;
  display: block;
  width: 125px;
  height: 38px;
  padding: 0;
  margin: 0 7px;
  border: none;
  background: none;
  letter-spacing: 0;
  font-weight: 700;
  font-size: 13px;
  transition: all 100ms;
  color: $fontBlack;
  cursor: pointer;

  span {
    position: relative;
    display: block;
  }

  &:focus {
    outline: none;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid rgba(0,0,0, 0.16);
    border-radius: 4px;
    background: #fff;
    transition: all 100ms;
    transform: scale(1);
    opacity: 1;
  }
}

.tabHide {
  border: none;
  font-weight: 500;
  box-shadow: none;
  background: rgba(0,0,0,0.04);
  border-radius: 4px;
  color: rgba($fontBlack, 0.45);

  &:before {
    transform: scale(0.7);
    opacity: 0;
  }

  &:hover {
    color: $fontBlack;
  }
}

.tabContent {
  position: relative;
  display: block;
}

.tabContentHide {
  display: none;
}

.tabText {
  position: relative;
  display: block;
  margin: 38px 0 30px;
  letter-spacing: 0.19px;
  font-size: 17px;
  line-height: 28px;
  color: $fontBlack;
}

.tabLink {
  position: relative;
  display: block;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: $fontBlack;

  img {
    padding-left: 10px;
  }
}
