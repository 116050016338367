.wrap {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 13px;
}

.dot {
  position: relative;
  display: block;
  width: 2px;
  height: 2px;
  margin: 0 2px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}
