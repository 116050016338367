@import "../constants/colors.scss";

.ant-menu {
  font-family: 'Roboto', sans-serif;
}

.ant-menu-horizontal {
  height: 64px;
  border: none;
  line-height: 64px;
  background: none;
}

.ant-menu-horizontal > .ant-menu-item {
  margin-right: 20px;
  border: 0;
  top: 0;
}

.ant-menu-horizontal > .ant-menu-item:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3.5px;
  border-radius: 1px;
  background-color: #F8CA00;
  background-image: linear-gradient(-45deg, #fcd531, #4ce9f5);
  opacity: 0;
  transition: opacity 100ms;
}

.ant-menu-horizontal > .ant-menu-submenu {
  margin-right: 30px;
}

.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  padding: 0;
  margin-left: 20px;
  height: 64px;
  border-bottom: none;
  font-size: 14px;
}

.ant-menu-submenu > .ant-menu-submenu-title {
  height: 36px;
  margin-left: -20px;
  margin-right: -30px;
  padding-left: 15px;
  padding-right: 31px;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  line-height: 34px;
}

.ant-menu-sub > .ant-menu-item-active:hover {
  background-color: rgba($tomeBlack, 0.04);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba($tomeBlack, 0.04);
}

.ant-menu-item-selected {
  pointer-events: none;
}

.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: none;
  border-top: 0;
  color: $fontBlack !important;
}

.ant-menu-horizontal > .ant-menu-item-open:before,
.ant-menu-horizontal > .ant-menu-item-selected:before,
.ant-menu-horizontal > .ant-menu-submenu-selected:before {
  opacity: 1;
}

.ant-menu-item,
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu,
.ant-menu-submenu-title,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: $fontBlack !important;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-submenu-open {
  border-bottom: none;

  color: rgba($tomeBlack, 0.48) !important;
}

.ant-menu-horizontal > .ant-menu-item-selected > a,
.ant-menu-horizontal > .ant-menu-item:hover > a,
.ant-menu-horizontal > .ant-menu-item > a {
  color: $fontBlack;
}

.ant-menu-submenu {
  display: flex;
  align-items: center;
}

.ant-menu-submenu-title {
  height: 61px;
}

.ant-menu-submenu-open > .ant-menu-submenu-title {
  color: rgba($tomeBlack, 0.48);
}

.ant-menu-submenu-open.ant-menu-submenu-horizontal > .ant-menu-submenu-title svg {
  margin-top: -2px;
  transform: rotateX(180deg);
  stroke: rgba($tomeBlack, 0.48);
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item:not(:last-child) {
  margin-bottom: 1.5px;
}

.ant-menu-sub > .ant-menu-item {
  margin: 0;
  height: 32px;
  line-height: 32px;
}

ul[id$="control$Menu"].ant-menu,
ul[id$="payments$Menu"].ant-menu {
  min-width: auto;
  margin-top: -7px;
  padding-top: 4.5px;
  padding-bottom: 4.5px;
  border: 1px solid rgba($tomeBlack, 0.16);
  border-top: none;
  box-shadow: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

ul[id$="control$Menu"].ant-menu {
  width: 131px;
}

ul[id$="payments$Menu"].ant-menu {
  width: 111px;
}