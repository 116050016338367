.headerWrap {
  position: relative;
  display: block;
  overflow: hidden;
  padding-top: 112px;
  margin-top: -112px;
  background-color: #fff;
}

.headerImage,
.headerImageMobile {
  position: absolute;
  left: calc(50% - 10px);
  transform: translateX(-50%);
}

.headerImage {
  top: -32px;
}

.headerImageMobile {
  bottom: 0;
  width: 940px;
  height: 564px;
}

.footerWrap {
  position: relative;
  display: block;
  z-index: 1;
}

.waveBottom {
  position: absolute;
  top: -221px;
  left: calc(50% - 18px);
  right: 0;
  transform: translateX(-50%);
}
