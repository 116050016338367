@import "../../constants/colors";

.wrap {
  position: relative;
  display: flex;
  border-radius: 24px;
  background-color: #fff;
}

.tab {
  position: relative;
  display: block;
  height: 48px;
  padding: 0 18px;
  background: none;
  border: none;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  color: $fontBlack;
  cursor: pointer;

  left: -1px;

  &:focus {
    outline: none;
  }

  @media only screen and (max-width: 1000px) {
    flex: 1;
    height: 40px;
  }
}

.tabActive {
  font-weight: 600;
}

.tabDisable {
  pointer-events: none;
  cursor: default;
  border-color: rgba($tomeBlack, 0.16) !important;
  background-image: none;
  background-color: rgba($tomeBlack, 0.05) !important;
  box-shadow: none;
  color: rgba($fontBlack, 0.45);

  .iconWrap {
    filter: grayscale(1);
    opacity: 0.3;
  }
}

.currentSegment {
  position: absolute;
  left: 0;
  top: 0;
  height: 48px;
  border-radius: 24px;
  background-image: linear-gradient(135deg, #ffe877, #ffec78);
  background-color: $tomeYellow;
  color: $fontBlack;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.06), 0 2px 1px rgba(0,0,0, 0.03);
  pointer-events: none;
  will-change: left, width;

  @media only screen and (max-width: 1000px) {
    height: 40px;
  }
}

.currentSegmentAnimate {
  transition: all 200ms;
  transition-timing-function: cubic-bezier(0.215,0.61,0.355,1);
}
