.wrap {
  position: relative;
  display: block;
  z-index: 1;
}

.wave {
  position: absolute;
  top: -221px;
  left: calc(50% - 18px);
  right: 0;
  transform: translateX(-50%);
  width: 4096px;
  height: 446px;
}
