@import "../../constants/colors";

.wrap {
  position: relative;
  display: block;
  margin: 0;
  padding: 33px 0;
  z-index: 10;

  @media only screen and (max-width: 900px) {
    padding: 32px 0;
  }
}

.content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.contentLeft {
  position: relative;
  display: flex;
  align-items: center;
}

.logoWrap {
  position: relative;
  display: block;
  min-width: 74px;
  text-decoration: none;

  @media only screen and (min-width: 901px) {
    padding-top: 5px;
    padding-left: 1px;
    margin-right: 78px;
  }
}

.menu {
  position: relative;
  display: flex;
  align-items: center;
}

.menuItem {
  position: relative;
  display: block;
  margin: 0 19px;
  letter-spacing: 0;
  font-size: 17px;
  line-height: 26px;
  color: $fontBlack;

  &:first-child { margin-left: 0; }
  &:last-child { margin-right: 0; }
}

.menuItemBusiness {
  padding: 0 10px;
  border: 3px solid #4ac9bf;
  border-radius: 6px;
  line-height: 31px;
}

.menuItemBusinessActive {
  background: #ddfff4;
}

.menuItemSelect {
  color: $tomeRed;
  &:before {
    content: '';
    position: absolute;
    top: -42px;
    left: 0;
    right: 0;
    height: 5px;
    border-radius: 1px;
    background-image: linear-gradient(135deg,#4ce9f5,#fcd531);
  }
}

.contentRight {
  position: relative;
  display: flex;
  align-items: center;
}

.buttonSignIn {
  width: 107px;
  height: 44px;
  padding-top: 2px;
  margin-left: 20px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 6px;

  &:hover {
    .buttonSignInImage {
      transform: translateX(3px);
    }
  }
}

.buttonSignInImage {
  display: inline-block;
  width: 13px;
  height: 11px;
  margin-top: -3px;
  margin-left: 6px;
  transition: transform 100ms;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjEwIiB2aWV3Qm94PSIwIDAgMTIgMTAiIHdpZHRoPSIxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iIzEyMTQxMyIgc3Ryb2tlLXdpZHRoPSIxLjQiPjxwYXRoIGQ9Im02Ljc1Nzk5NDYyLjY3NTM2NjY4IDQuMjA4OTM4NDggNC4yNTE0NTMwMy00LjI1MjgxNjAyIDQuMjk1NzczNzciIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48cGF0aCBkPSJtMTAuNzc1Njc1MiA0Ljk1ODk1NjY2aC0xMC4yMjU2NzUyIi8+PC9nPjwvc3ZnPg==");
  background-size: contain;
}
