@import "../../constants/colors";

.wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-height: 100vh;
  background-color: #f2f2f1;
  z-index: 999;
}

.backgroundCircle {
  position: absolute;
  top: -50vw;
  left: -50vw;
  width: 100vw;
  height: 100vw;
  border-radius: 50%;
  background: radial-gradient(#f6f6f5, #f2f2f1);
  z-index: 0;
  pointer-events: none;
}

.back {
  position: fixed;
  top: 32px;
  left: 32px;
  width: auto;
  padding: 0 20px;
  box-shadow: none;
  background: rgba(18,20,19,0.05);

  &:hover {
    box-shadow: none;

    .backImg {
      left: -3px;
    }
  }
}

.backImg {
  position: relative;
  left: 0;
  margin-top: -2px;
  margin-right: 6px;
  transition: left 0.2s;
  transform: rotate(180deg);
}

.box {
  position: relative;
  display: block;
  overflow: hidden;
  max-width: 612px;
  width: 100%;
  padding-top: 14px;
  margin-top: -200px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 0 110px -35px rgba($tomeBlack, 0.05);

  @media only screen and (max-width: 1000px) {
    min-height: calc(100vh - 64px);
  }

  @media all and (max-height: 800px) {
    margin-top: -60px;
  }

  @media all and (max-height: 600px) {
    margin-top: 0;
  }
}

.content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 384px;
  width: 100%;
  padding-top: 14px;

  @media only screen and (max-width: 1000px) {
    height: auto;
    padding: 48px 24px;
  }
}

.image {
  width: 291px;
  height: 183px;
  margin-left: -38px;
  margin-bottom: 18px;

  @media only screen and (max-width: 1000px) {
    margin-left: -18px;
  }
}

.title {
  margin: 0 auto 9px;
  letter-spacing: 0;
  font-weight: 500;
  font-size: 17px;
  line-height: 32px;
  color: $fontBlack;

  @media only screen and (max-width: 1000px) {
    margin: 22px 0 15px;
    font-size: 21px;
    line-height: 30px;
  }
}

.text {
  max-width: 310px;
  margin: 0 auto;
  letter-spacing: 0;
  font-size: 14px;
  line-height: 22px;
  color: rgba($tomeBlack, 0.56);

  @media only screen and (max-width: 1000px) {
    max-width: none;
    margin: 0;
    font-size: 15px;
    line-height: 24px;
  }
}

.boxBottom {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 120px;
  padding: 38px 48px;

  &:before {
    content: '';
    position: absolute;
    top: 11px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
  }

  @media only screen and (max-width: 1000px) {
    height: auto;
    padding: 24px;
  }
}

.boxBottomCornerWrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: block;
}

.boxBottomCorner {
  position: relative;
  display: block;
  margin-left: 35px;
  width: 30px;
  height: 20px;
}

.boxBottomCornerLine {
  position: absolute;
  top: 0;
  height: 20px;
  border-top: 1px solid #E7E7E7;
  background-color: #fff;
}

.boxBottomCornerLineLeft {
  left: 0;
  width: 35px;
}

.boxBottomCornerLineRight {
  left: 35px + 30px;
  right: 0;
}

.boxBottomTextWrap {
  position: relative;
  display: flex;
  align-items: center;
}

.boxBottomText span {
  position: relative;
  display: block;
  margin-left: 13px;
  font-size: 12px;
  line-height: 18px;
  color: $fontBlack;
}

.boxBottomButton {
  width: auto;
  padding: 0 20px;

  @media only screen and (max-width: 1000px) {
    flex: 1;
  }
}
