@import "../../constants/colors";

.wrap {
  position: relative;
  display: block;
  margin-top: -100px;
  margin-bottom: -224px;
  padding: 260px 0 446px;
  background: linear-gradient(rgba(#000, 0.012), rgba(#000, 0.018));

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(rgba(#000, 0.02), rgba(#000, 0.03));
    pointer-events: none;
  }
}

.wave {
  position: absolute;
  top: 0;
  left: calc(50% - 18px);
  right: 0;
  transform: translateX(-50%);
}

.pattern {
  position: absolute;
  top: -95px;
  left: 515px;
}

.blobs {
  position: absolute;
  top: -10px;
  right: 28px;
}

.contentTop {
  position: relative;
  display: block;
  max-width: 511px;
}

.title {
  margin: 0;
  letter-spacing: -0.65px;
  font-weight: 600;
  font-size: 27px;
  line-height: 48px;
  color: $fontBlack;
}

.text {
  position: relative;
  display: block;
  max-width: 445px;
  margin: 14px 0 30px;
  letter-spacing: 0.19px;
  font-size: 17px;
  line-height: 28px;
  color: $fontBlack;
}

.titleLink {
  position: relative;
  display: block;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: $fontBlack;

  &:hover {
    .titleLinkImage {
      transform: translate(3px, 0);
    }
  }
}

.titleLinkImage {
  display: inline-block;
  width: 15px;
  height: 12px;
  margin-left: 9px;
  transition: transform 0.2s;

  background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjEwIiB2aWV3Qm94PSIwIDAgMTIgMTAiIHdpZHRoPSIxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iIzEyMTQxMyIgc3Ryb2tlLXdpZHRoPSIxLjQiPjxwYXRoIGQ9Im02Ljc1Nzk5NDYyLjY3NTM2NjY4IDQuMjA4OTM4NDggNC4yNTE0NTMwMy00LjI1MjgxNjAyIDQuMjk1NzczNzciIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48cGF0aCBkPSJtMTAuNzc1Njc1MiA0Ljk1ODk1NjY2aC0xMC4yMjU2NzUyIi8+PC9nPjwvc3ZnPg==");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.reasonsWrap {
  position: relative;
  display: flex;
  margin-top: 90px;

  @media only screen and (min-width: 1080px) {
    margin-left: -40px;
    margin-right: -40px;
  }
}

.reason {
  flex: 1;
  position: relative;
  display: block;
  box-sizing: border-box;
  min-height: 200px;
  padding: 22px 40px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba($tomeBlack, 0.01), 0 3px 8px 0 rgba(0,0,0, 0.04);

  &:nth-child(2) {
    margin: 0 48px;
  }
}

.reasonTitle {
  margin: 0 0 14px;
  letter-spacing: -0.65px;
  font-weight: 600;
  font-size: 27px;
  line-height: 48px;
  color: $fontBlack;
}

.reasonText {
  margin: 0;
  letter-spacing: 0.19px;
  font-size: 17px;
  line-height: 28px;
  color: $fontBlack;
}

.reasonLink {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: none;
  border: none;
}
