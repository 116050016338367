.circle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 4px 10px 0 rgba(0,0,0, 0.08);

  &:focus {
    outline: none;
  }
}

.circleButton {
  cursor: pointer;
}
