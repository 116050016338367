@import "../constants/colors.scss";

$tooltipBgColor: rgba(#000, 0.8);

.input-tooltip {
  &.ant-tooltip {
    min-width: 120px;
    max-width: 375px;
  }

  .ant-tooltip-inner {
    display: inline-table;
    max-width: 220px;
    min-height: 26px;
    padding: 5px 9px;
    font-size: 12px;
    line-height: 16px;
    color: rgba(#fff, 0.95);
    border-radius: 3px;
    background: $tooltipBgColor;
    box-shadow: 0 -2px 8px 0 rgba(#000, 0.15), inset 0 0 0 1px rgba(#000, 0.16);

    div {
      display: inline;
    }
  }

  //.ant-tooltip-inner-content {
  //  display: inline;
  //}

  .ant-tooltip-arrow {
    border: none;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &.ant-tooltip-placement-topRight {
    text-align: right;
  }

  &.ant-tooltip-placement-topRight,
  &.ant-tooltip-placement-bottomRight {
    .ant-tooltip-arrow {
      right: 9px;
    }
  }

  &.ant-tooltip-placement-topLeft,
  &.ant-tooltip-placement-bottomLeft {
    .ant-tooltip-arrow {
      left: 9px;
    }
  }

  &.ant-tooltip-placement-top,
  &.ant-tooltip-placement-topLeft,
  &.ant-tooltip-placement-topRight {
    .ant-tooltip-arrow {
      bottom: 1px;
      width: 16px;
      height: 7px;
      background-image: url('antStyles/tooltipArrow/top.svg');
    }
  }

  &.ant-tooltip-placement-bottom,
  &.ant-tooltip-placement-bottomLeft,
  &.ant-tooltip-placement-bottomRight {
    .ant-tooltip-arrow {
      top: 1px;
      width: 16px;
      height: 7px;
      background-image: url('antStyles/tooltipArrow/bottom.svg');
    }
  }

  &.ant-tooltip-placement-left,
  &.ant-tooltip-placement-leftTop,
  &.ant-tooltip-placement-leftBottom {
    .ant-tooltip-arrow {
      right: 1px;
      width: 7px;
      height: 17px;
      margin-top: -8.5px;
      background-image: url('antStyles/tooltipArrow/left.svg');
    }
  }

  &.ant-tooltip-placement-right,
  &.ant-tooltip-placement-rightTop,
  &.ant-tooltip-placement-rightBottom {
    .ant-tooltip-arrow {
      left: 1px;
      width: 7px;
      height: 17px;
      margin-top: -8.5px;
      background-image: url('antStyles/tooltipArrow/right.svg');
    }
  }
}

.input-tooltipPointless {
  pointer-events: none;
}

.input-info-tooltip {
  .ant-tooltip-inner {
    max-width: 165px;
  }

  &.ant-tooltip-placement-topRight {
    padding-bottom: 12px;
    .ant-tooltip-arrow {
      bottom: 5px;
      right: 4px;
    }
  }
}